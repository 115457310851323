import React, { useRef, useState, useEffect, useLayoutEffect } from "react";
import useResizeObserver from '@react-hook/resize-observer';
import '../css/d3.css'
import {
    select,
    line,
    curveCardinal,
    scaleLinear,
    axisBottom,
    axisLeft,
    svg,
    scaleTime,
    extent,
    timeMonth,
    timeDay,
    min,
    max
} from "d3";
import { Tooltip } from 'react-tooltip';

const useSize = (target) => {
    const [size, setSize] = useState()
    useLayoutEffect(() => {
        setSize(target.current.getBoundingClientRect())
    }, [target])
    useResizeObserver(target, (entry) => setSize(entry.contentRect))
    return size
}
//chart component
export default function D3PNL(props) {
    //refs
    const svgRef = useRef();
    const boundingRef = useRef();
    const size = useSize(boundingRef);
    const [avgPnL, setAvgPnL] = useState(0.0);

    var maxY = 0;
    var minY = 1000;


    const parseData = (inputData, is_overall) => {

        if (!inputData) {
            inputData = [];
        }

        const data = [];
        for (let i = 0; i < inputData.length; i++) {
            const datum = inputData[i];
            const valueLine = datum.price;
            var valueBar = (typeof datum.return === 'number' ? datum.return : (typeof datum.return5 === 'number' ? datum.return5 : datum.return15));
            const valueCol = (typeof datum.ei === 'number' ? datum.ei : (typeof datum.ei5 === 'number' ? datum.ei5 : datum.ei15));
            const cumulative_pnl = (typeof datum.cumulativePNL === 'number' ? datum.cumulativePNL : (typeof datum.cumulativePNL === 'number' ? datum.cumulativePNL : datum.cumulativePNL));
            const startDate = datum.date;
            const endDate = (datum.release_date ? datum.release_date : (datum.release_date5 ? datum.release_date5 : datum.release_date15));

            var sig = "N/A"
            if (valueCol > props.threshold) {
                sig = "Buy";
            } else if (valueCol < -props.threshold) {
                sig = "Sell"
            } else {
                sig = "Hold"
                if (!is_overall)
                    valueBar = 0.0
            }

            const eiTooltip = `${sig} with ${(Math.abs(valueCol) * 100).toFixed(1)}% confidence`

            if (valueLine > maxY) {
                maxY = valueLine
            }
            if (valueLine < minY) {
                minY = valueLine
            }
            if (!isNaN(new Date(datum.date))) {
                data.push({
                    date: (new Date(datum.date)).toISOString().split('T')[0], // Convert date to "YYYY-MM-DD" format
                    startDate,
                    endDate,
                    valueLine,
                    valueBar,
                    valueCol,
                    eiTooltip,
                    cumulative_pnl
                });
            };
        }

        return data;
    };

    const data = parseData(props.data, props.is_overall);
    // if(props.is_overall)
    //     console.log("overall data", data);
    // let sumPnL = 0.0;
    // data.forEach(obj => {
    //     sumPnL += obj.cumulative_pnl;
    // });

    const maxRange = maxY - minY;

    maxY += .15 * maxRange;
    minY -= .15 * maxRange;

    function getFormattedDate(date) {
        var year = date.getFullYear();

        var month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;

        var day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;

        return month + '/' + day + '/' + year;
    }

    //draws chart
    useEffect(() => {
        // console.log(props.cumulativePNLArray);
        if (props.is_overall && props.cumulativePNLArray) {
          let totalPNLs = 0;
          
          props.cumulativePNLArray.forEach(doc => {
            totalPNLs += doc.cumulativePNLs;
          });
          
          setAvgPnL(totalPNLs / props.cumulativePNLArray.length);
        }
        const svg = select(svgRef.current);
        svg.selectAll("*").remove();
        if (!size) return
        const width = size.width;
        const height = size.height;
        //scales
        var scaleOffset = (props.small ? { y: 10, x: 15 } : { y: 30, x: 20 })

        const xScale = scaleTime()
            .domain(extent(data, d => new Date(d.date)))
            .range([scaleOffset.y, width - scaleOffset.x]);
        const yScale = scaleLinear().domain(extent([minY, maxY])).range([height - scaleOffset.y, scaleOffset.x]);

        const bubbleScale = scaleLinear()
            .domain([min(data, d => Math.abs(d.valueCol)), max(data, d => Math.abs(d.valueCol))])
            .range([0.8, 1.5])

        //axes
        const xAxis = axisBottom(xScale).ticks(9).tickFormat(d => getFormattedDate(d));

        const yAxis = axisLeft(yScale);

        // Create a line generator
        const lineGenerator = line()
            .x(d => xScale(new Date(d.date)))
            .y(d => yScale(d.valueLine));

        // Create the line chart
        svg.append("path")
            .datum(data)
            .attr("class", "line")
            .attr("d", lineGenerator)
            .style("stroke", "var(--steelblue)")
            .style("fill", "none");

        var barWidth = (props.small ? 3 : 10)

        function barHeight(value) {
            return Math.abs(value * 500) * (props.small ? .20 : 1) * (props.is_overall ? 20 : 1)
        }

        function createTooltip(d) {
            try {
                return `<h3 style="margin: 0">$${d.valueLine.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]} | PnL: ${(d.valueBar * 100).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]}%</h3>Holding Period: <strong>${getFormattedDate(new Date(d.startDate))} to ${getFormattedDate(new Date(d.endDate))}</strong>${props.is_overall ? "" : `<br />Equity Indicator: <strong>${d.eiTooltip}</strong>`}`
            }
            catch {
                return `Failed to load information.`
            }
        }

        // Create a bar chart
        svg.selectAll(".bar")
            .data(data)
            .enter()
            .append("rect")
            .attr("class", "bar")
            .attr("x", d => xScale(new Date(d.date)) - barWidth / 2)
            .attr("y", d => (d.valueBar < 0) ? yScale(d.valueLine) : yScale(d.valueLine) - barHeight(d.valueBar))
            .attr("width", barWidth)
            .attr("height", d => barHeight(d.valueBar))
            .style("fill", d => (d.valueBar < 0) ? "var(--red)" : "var(--green)")
            .attr("data-tooltip-id", "d3-pnl-tooltip")
            .attr("data-tooltip-html", d => createTooltip(d));

        // Add bubbles to the line chart
        function dotcolor(d) {
            if (props.is_overall) return "var(--steelblue)";
            if (d.valueCol < -1 * props.threshold) return "var(--red)";
            if (d.valueCol > props.threshold) return "var(--green)";
            return "var(--yellow)";
        }

        if (data.length > 0) {
            const last = data[data.length - 1];
            svg.append("circle")
                .attr("class", "bubble bubble-pulse")
                .attr("cx", xScale(new Date(last.date)))
                .attr("cy", yScale(last.valueLine))
                .attr("r", props.is_overall ? 3 : (.6 * barWidth * bubbleScale(Math.abs(last.valueCol))))
                .style("stroke", dotcolor(last));
        }

        svg.selectAll(".bubble")
            .data(data)
            .enter()
            .append("circle")
            .attr("class", "bubble")
            .attr("cx", d => xScale(new Date(d.date)))
            .attr("cy", d => yScale(d.valueLine))
            .attr("r", d => props.is_overall ? 3 : (.6 * barWidth * bubbleScale(Math.abs(d.valueCol))))
            .style("fill", dotcolor)
            .attr("data-tooltip-id", "d3-pnl-tooltip")
            .attr("data-tooltip-html", d => createTooltip(d));

        var color = "rgba(var(--yellow-rgb), 0.9)";
        if ((props.is_overall ? avgPnL : props.cumulativePNL) > 0.0) color = "rgba(var(--green-rgb), 0.9)";
        else if ((props.is_overall ? avgPnL : props.cumulativePNL) < 0.0) color = "rgba(var(--red-rgb), 0.9)";

        var tooltiptext = props.is_overall ? "Avg Cumulative PnL per Annum\n Can be slightly different than individual PnL due to rouding error, different confidence threshold and regime" : "Avg Cumulative PnL per Annum"
        svg.append("rect")
            .attr("x", 2)
            .attr("y", 7)
            .attr("rx", 10)
            .attr("ry", 10)
            .attr("width", 70)
            .attr("height", 18)
            .style("fill", color)
            .attr("data-tooltip-id", "d3-pnl-tooltip")
            .attr("data-tooltip-html", tooltiptext);

        svg.append("text")
            .attr("x", 35)
            .attr("y", 20)
            .attr("text-anchor", "middle")
            .style("font-size", "11px")
            .style("fill", "black")
            //.text((props.cumulativePNL > 0.0) ? `+${(props.cumulativePNL * 100).toFixed(2)}%` : `${(props.cumulativePNL * 100).toFixed(2)}%`);
            .text((props.is_overall ? avgPnL : props.cumulativePNL) > 0.0
                ? `+${((props.is_overall ? avgPnL : props.cumulativePNL) * 100).toFixed(2)}%`
                : `${((props.is_overall ? avgPnL : props.cumulativePNL) * 100).toFixed(2)}%`)
            .attr("data-tooltip-id", "d3-pnl-tooltip")
            .attr("data-tooltip-html", tooltiptext);


        // Append axes to the chart


        if (!props.small) {
            const legendData = !props.is_overall ? [
                { label: "Buy Signal", color: "rgba(var(--green-rgb), 0.9)", type: 'circle' },
                { label: "Sell Signal", color: "rgba(var(--red-rgb), 0.9)", type: 'circle' },
                { label: "Positive PnL", color: "rgba(var(--green-rgb), 0.9)", type: 'rect' },
                { label: "Negative PnL", color: "rgba(var(--red-rgb), 0.9)", type: 'rect' }
            ] : [
                { label: "Positive PnL", color: "rgba(var(--green-rgb), 0.9)", type: 'rect' },
                { label: "Negative PnL", color: "rgba(var(--red-rgb), 0.9)", type: 'rect' }
            ];
            const legend = svg.append("g")
                .attr("class", "legend")
                .attr("transform", `translate(100, 20)`);

            const legendItems = legend.selectAll(".legend-item")
                .data(legendData)
                .enter()
                .append("g")
                .attr("class", "legend-item")
                .attr("transform", (d, i) => `translate(${i * 150}, 0)`)

            legendItems.each(function (d) {
                const item = select(this);
                item.append("text")
                    .attr("x", 20)
                    .attr("y", 2)
                    .text(d.label)
                    .style("font-size", "12px")
                    .attr("alignment-baseline", "middle");

                if (d.type === 'circle') {
                    item.append("circle")
                        .attr("r", 10)
                        .style("fill", d.color);
                } else if (d.type === 'rect') {
                    item.append("rect")
                        .attr("width", 15)
                        .attr("height", 20)
                        .style("fill", d.color)
                        .attr("transform", `translate(0, -10)`);
                }

            });
            svg.append("g")
                .attr("class", "x-axis")
                .attr("transform", `translate(0,${height - 30})`)
                .call(xAxis);

            svg.append("g")
                .attr("class", "y-axis")
                .attr("transform", "translate(30,0)")
                .attr("data-tooltip-id", "d3-pnl-tooltip")
                .attr("data-tooltip-html", "Equity Price ($)")
                .call(yAxis);
        } else {
            const xAxisSmall = axisBottom(xScale).ticks(9).tickFormat((d, i) => { if (i === 1) { return getFormattedDate(new Date(data[1].date)) } else if (i === 6) { return getFormattedDate(new Date(data[data.length - 1].date)) } else { return "" } });
            svg.append("g")
                .attr("class", "x-axis")
                .attr("transform", `translate(0,${height - 20})`)
                .call(xAxisSmall);
        }
    }, [data, size, props.is_overall, props.cumulativePNLArray]);

    var boundingStyle = { height: "100%", width: "100%" }

    if (props.small) {
        boundingStyle = { width: "90%", height: "80px" }
    }

    return (
        <div ref={boundingRef} style={boundingStyle}>
            <div className={props.small ? "d3-bounding-anim-once" : ""} style={boundingStyle}>
                <svg ref={svgRef} style={props.small ? { height: "100%", width: "100%" } : { padding: "10px", height: "100%", width: "100%" }}>
                </svg>
            </div>
            {!props.small ? <Tooltip id="d3-pnl-tooltip" multiline={true} data-html={true} style={{ zIndex: "105", backgroundColor: "white", color: "black", boxShadow: "0 0 5px #D9D9D9" }} /> : null}
        </div>
    );
};
