import React, { useRef, useState, useEffect } from "react";
import '../css/d3.css'
import {
    select,
    line,
    scaleLinear,
    axisBottom,
    axisLeft,
    scaleTime,
    extent
} from "d3";
import { Tooltip } from 'react-tooltip';

//chart component
export default function D3Sentiment(props) {

    //refs
    const svgRef = useRef();
    const boundingRef = useRef();
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    const data = props.data;

    var emptyDataEnd = []; // consecutive all null values at the end of data
    for (let i = data.length - 1; i >= 0; i--) {
        if (!data[i].high && !data[i].avg && !data[i].low) {
            emptyDataEnd.push({ date: data[i].date, val: 0 });
        } else {
            if (emptyDataEnd.length > 0) {
                let toUse = 0;
                toUse = (data[i].high ? data[i].high : toUse);
                toUse = (data[i].low ? data[i].low : toUse);
                toUse = (data[i].avg ? data[i].avg : toUse);
                emptyDataEnd.push({ date: data[i].date, val: toUse });
            }
            emptyDataEnd = emptyDataEnd.reverse();
            break;
        }
    }

    useEffect(() => {
        function handleWindowResize() {
            setWidth(boundingRef.current.offsetWidth);
            setHeight(boundingRef.current.offsetHeight);
            svgRef.current.style.width = boundingRef.current.offsetWidth;
            svgRef.current.style.height = boundingRef.current.offsetHeight;
        }

        handleWindowResize();

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [])

    function getFormattedDate(date) {
        var year = date.getFullYear();

        var month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;

        var day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;

        return month + '/' + day + '/' + year;
    }

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    //draws chart
    useEffect(() => {
        const svg = select(svgRef.current);
        svg.selectAll("*").remove();

        //scales
        var scaleOffset = (props.small ? { y: 10, x: 10 } : { y: 30, x: 20 })

        const xScale = scaleTime()
            .domain(extent(data, d => new Date(d.date)))
            .range([scaleOffset.y, width - scaleOffset.x]);

        const yScale = scaleLinear().domain([-1, 1]).range([height - scaleOffset.y, scaleOffset.x]);

        //axes
        const xAxis = axisBottom(xScale).tickFormat(d => getFormattedDate(d));
        const yAxis = axisLeft(yScale);

        // Create the line chart
        svg.append("path")
            .datum(emptyDataEnd)
            .attr("class", "line")
            .attr("d", line()
                .x(d => xScale(new Date(d.date)))
                .y(d => yScale(d.val))
            )
            .style("stroke", "var(--steelblue)")
            .style("stroke-dasharray", ("3, 3"))
            .style("fill", "none");

        var counter = 0;

        svg.append("path")
            .datum(data)
            .attr("class", "line")
            .attr("d", line()
                .x((d, i) => {
                    if (d.high) {
                        return xScale(new Date(d.date))
                    } else {
                        return xScale(new Date(data[counter].date))
                    }
                })
                .y((d, i) => {
                    if (d.high) {
                        counter = i;
                        return yScale(d.high)
                    } else {
                        return yScale(data[counter].high ? data[counter].high : 0)
                    }
                }))
            .style("stroke", "var(--green)")
            .style("fill", "none");

        counter = 0;

        svg.append("path")
            .datum(data)
            .attr("class", "line")
            .attr("d", line()
                .x((d, i) => {
                    if (d.avg) {
                        return xScale(new Date(d.date))
                    } else {
                        return xScale(new Date(data[counter].date))
                    }
                })
                .y((d, i) => {
                    if (d.avg) {
                        counter = i;
                        return yScale(d.avg)
                    } else {
                        return yScale(data[counter].avg ? data[counter].avg : 0)
                    }
                }))
            .style("stroke", "var(--blue)")
            .style("fill", "none");

        counter = 0;

        svg.append("path")
            .datum(data)
            .attr("class", "line")
            .attr("d", line()
                .x((d, i) => {
                    if (d.low) {
                        return xScale(new Date(d.date))
                    } else {
                        return xScale(new Date(data[counter].date))
                    }
                })
                .y((d, i) => {
                    if (d.low) {
                        counter = i;
                        return yScale(d.low)
                    } else {
                        return yScale(data[counter].low ? data[counter].low : 0)
                    }
                }))
            .style("stroke", "var(--red)")
            .style("fill", "none");

        if (!props.small) {
            // Append axes to the chart
            svg.append("g")
                .attr("class", "x-axis")
                .attr("transform", `translate(0,${height - 30})`)
                .call(xAxis);

            svg.append("g")
                .attr("class", "y-axis")
                .attr("transform", "translate(30,0)")
                .attr("data-tooltip-id", "d3-sentiment-tooltip")
                .attr("data-tooltip-html", "Sentiment Score (-1/bearish to 1/bullish)")
                .call(yAxis);

            svg.selectAll(".hbubble")
                .data(data)
                .enter()
                .append("a")
                .attr("xlink:href", d => d.hlink)
                .attr("target", "_blank")
                .attr("data-tooltip-id", "d3-sentiment-tooltip")
                .attr("data-tooltip-html", d => (d.high ? `High Sentiment: ${d.high ? d.high.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] : "N/A"} on ${d.date ? d.date.split("T")[0] : "N/A"}<br /><p style="width: 300px">${d.hsum}</p>` : ""))
                .append("circle")
                .attr("class", "bubble")
                .attr("cx", d => xScale(new Date(d.date)))
                .attr("cy", d => yScale(d.high))
                .attr("r", 5)
                .style("fill", "var(--green)")
                .style("visibility", d => (d.high ? "visible" : "hidden"));

            svg.selectAll(".abubble")
                .data(data)
                .enter()
                .append("a")
                .attr("xlink:href", d => d.alink)
                .attr("target", "_blank")
                .attr("data-tooltip-id", "d3-sentiment-tooltip")
                .attr("data-tooltip-html", d => (d.avg ? `Average Sentiment: ${d.avg ? d.avg.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] : "N/A"} on ${d.date ? d.date.split("T")[0] : "N/A"}<br /><p style="width: 300px">${d.asum}</p>` : ""))
                .append("circle")
                .attr("class", "bubble")
                .attr("cx", d => xScale(new Date(d.date)))
                .attr("cy", d => yScale(d.avg))
                .attr("r", 5)
                .style("fill", "var(--blue)")
                .style("visibility", d => (d.avg ? "visible" : "hidden"));

            svg.selectAll(".lbubble")
                .data(data)
                .enter()
                .append("a")
                .attr("xlink:href", d => d.llink)
                .attr("target", "_blank")
                .attr("data-tooltip-id", "d3-sentiment-tooltip")
                .attr("data-tooltip-html", d => (d.low ? `Low Sentiment: ${d.low.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]} on ${d.date ? d.date.split("T")[0] : "N/A"}<br /><p style="width: 300px">${d.lsum}</p>` : ""))
                .append("circle")
                .attr("class", "bubble")
                .attr("cx", d => xScale(new Date(d.date)))
                .attr("cy", d => yScale(d.low))
                .attr("r", 5)
                .style("fill", "var(--red)")
                .style("visibility", d => (d.low ? "visible" : "hidden"));
        } else {
            var color = "rgba(var(--yellow-rgb), 0.9)";
            if (props.insider_trading_data["current_year_percent"] > props.insider_trading_data["previous_year_percent"]) color = "rgba(var(--green-rgb), 0.9)";
            else if (props.insider_trading_data["current_year_percent"] < props.insider_trading_data["previous_year_percent"]) color = "rgba(var(--red-rgb), 0.9)";
            svg.append("rect")
                .attr("x", 2)
                .attr("y", 7)
                .attr("rx", 10)
                .attr("ry", 10)
                .attr("width", 46)
                .attr("height", 18)
                .style("fill", color);

            svg.append("text")
                .attr("x", 25)
                .attr("y", 20)
                .attr("text-anchor", "middle")
                .style("font-size", "11px")
                .style("fill", "black")
                .text(Math.abs(props.insider_trading_data["current_year_percent"] - props.insider_trading_data["previous_year_percent"]) >= 1 ? "**.**%"
                    : (props.insider_trading_data["current_year_percent"] >= props.insider_trading_data["previous_year_percent"] ? "+" : "") + (Math.round((props.insider_trading_data["current_year_percent"] - props.insider_trading_data["previous_year_percent"]) * 10000) / 100).toFixed(2) + "%");
        }
    }, [data, emptyDataEnd, width, height, props.small]);

    var boundingStyle = { height: "100%", width: "100%" }

    if (props.small) {
        boundingStyle = { width: "90%", height: "80px" }
    }
    else {
        boundingStyle = { width: "100%", height: "calc(80vh - 175px)" }
    }

    return (
        <div ref={boundingRef} style={boundingStyle}>
            {!props.small && (
                <div className="insider-data">
                    <p style={{ marginBottom: '0px' }}><b>Insider Trading Data (cumulative net shares):</b></p>
                    <p style={{ margin: '0px' }}>{String((new Date().getFullYear()) - 1) + ": " + (props.insider_trading_data["previous_year_percent"] >= 0
                        ? "Transactors bought " + (Math.round(Math.abs(props.insider_trading_data["previous_year_percent"]) * 10000) / 100).toFixed(2) + "% more shares"
                        : "Transactors sold " + (Math.round(Math.abs(props.insider_trading_data["previous_year_percent"]) * 10000) / 100).toFixed(2) + "% of shares")}
                        {" (" + (props.insider_trading_data["previous_year_shares"][1] != 0
                            ? numberWithCommas(Math.round(props.insider_trading_data["previous_year_shares"][1])) + " to " + numberWithCommas(Math.round(Number(props.insider_trading_data["previous_year_shares"][1]) + Number(props.insider_trading_data["previous_year_shares"][0])))
                            : "0 transactions") + ")"}
                    </p>
                    <p style={{ margin: '0px' }}>{String((new Date().getFullYear())) + ": " + (props.insider_trading_data["current_year_percent"] >= 0
                        ? "Transactors bought " + (Math.round(Math.abs(props.insider_trading_data["current_year_percent"]) * 10000) / 100).toFixed(2) + "% more shares"
                        : "Transactors sold " + (Math.round(Math.abs(props.insider_trading_data["current_year_percent"]) * 10000) / 100).toFixed(2) + "% of shares")}
                        {" (" + (props.insider_trading_data["current_year_shares"][1] != 0
                            ? numberWithCommas(Math.round(props.insider_trading_data["current_year_shares"][1])) + " to " + numberWithCommas(Math.round(Number(props.insider_trading_data["current_year_shares"][1]) + Number(props.insider_trading_data["current_year_shares"][0])))
                            : "0 transactions") + ")"}
                    </p>
                    {props.insider_trading_data["recent_report_link"] != "No Insider Trading Links Available" ? (
                        <p style={{ margin: '0px' }}>
                            <a href={props.insider_trading_data["recent_report_link"]} target="_blank" rel="noopener noreferrer">Click here for the most recent SEC filing.</a>
                        </p>
                    ) : (
                        <p style={{ margin: '0px' }}>{props.insider_trading_data["recent_report_link"]}</p>
                    )}
                </div>
            )}
            <div className={props.small ? "d3-bounding-anim-once" : ""}>
                <svg ref={svgRef} style={props.small ? { height: "100%", width: "100%" } : { padding: "10px", height: "100%", width: "100%" }}></svg>
            </div>
            {!props.small ? <Tooltip id="d3-sentiment-tooltip" multiline={true} data-html={true} style={{ zIndex: "105", backgroundColor: "white", color: "black", boxShadow: "0 0 5px #D9D9D9" }} /> : null}
        </div>
    );
};
