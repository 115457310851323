import '../css/public-components.css';
import { Authenticate } from './header.js';
import React, { useRef, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useCallback } from "react";
import Particles from "react-particles";
import { loadSlim } from "tsparticles-slim";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";


export function ScrollToHashElement() {
    let location = useLocation();

    let hashElement = useMemo(() => {
        let hash = location.hash;
        const removeHashCharacter = (str) => {
            const result = str.slice(1);
            return result;
        };

        if (hash) {
            let element = document.getElementById(removeHashCharacter(hash));
            return element;
        } else {
            return null;
        }
    }, [location]);

    useEffect(() => {
        if (hashElement) {
            hashElement.scrollIntoView({
                behavior: "smooth",
                // block: "end",
                inline: "nearest",
            });
        }
    }, [hashElement]);

    return null;
};


export function PublicNavbar({ forceRedirectIfNoAccount = true, forceRedirectIfSubscriptionNotActive = true }) {
    return (
        <div className="public-navbar">
            <Authenticate
                forceRedirectIfNoAccount={forceRedirectIfNoAccount}
                forceRedirectIfSubscriptionNotActive={forceRedirectIfSubscriptionNotActive}
            />
        </div>
    )
}

export function PublicFooter() {

    return (
        <div className="public-footer">
            <div>
                <h3 className="home-h3 home-special-copyright"><Link to="/"><img className="public-footer-logo" src="/images/logo-dark-new.png" alt="" /></Link>| Building the Future of Active Trading.<sup>©</sup></h3>
            </div>
            <div className="public-footer-section">
                <div className="public-footer-contact">
                    <h3 className="public-footer-h3">Address</h3>
                    <p className="public-footer-p">Suite 2023<br />2 Park Avenue<br />New York, NY 10016</p>
                    <h3 className="public-footer-h3">Phone</h3>
                    <p className="public-footer-p">US +1-203-609-0177<br />US +1-203-570-7634 (Investor Interests)</p>
                    <h3 className="public-footer-h3">Email</h3>
                    <p className="public-footer-p">info@bam.money</p>
                </div>
                <div className="public-footer-contact">
                    <a href="/demo/par"><h3 className="public-footer-h3">{"Alice (your personal assistant)"}</h3></a>
                    <a href="/company"><h3 className="public-footer-h3">About Us</h3></a>
                    <a href="/contact"><h3 className="public-footer-h3">Contact Us</h3></a>
                    <a href="/subscription"><h3 className="public-footer-h3">Pricing and Subscriptions</h3></a>
                    <a href="/faq"><h3 className="public-footer-h3">FAQ</h3></a>
                </div>
                <div className="public-footer-contact">
                    <a href="/resources"><h3 className="public-footer-h3">Resources</h3></a>
                    <a href="/how-it-works"><h3 className="public-footer-h3">How it Works</h3></a>
                    <a href="https://developers.bam.money/"><h3 className="public-footer-h3">API Docs</h3></a>
                    <a href="/terms"><h3 className="public-footer-h3">Terms and Conditions</h3></a>
                    <a href="/confidentiality-and-privacy"><h3 className="public-footer-h3">Confidentiality and Privacy</h3></a>
                </div>
            </div>
            <p className="public-footer-copyright">© 2024 All Rights Reserved BAM.money, Inc.</p>
        </div>
    )
}

export function GradientAnimation(props) {
    const ref = useRef(null);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = '/js/gradient-animation-min.js';
        ref.current.appendChild(script);
    }, []);

    return (
        <div ref={ref} className={`${props.class}`}>
            <canvas id="gradient-canvas" data-transition-in></canvas>
        </div>
    );
}

export function PublicTitle(props) {
    return (
        <div className="public-title">
            <h1>{props.title}</h1>
            {props.help ? props.help : <></>}
        </div>
    );
}

export function ParticleBackground() {
    const particlesInit = useCallback(async engine => {
        await loadSlim(engine);
    }, []);

    return (
        <Particles id="tsparticles" url="/particles_background.json" init={particlesInit} />
    );
};
