import { PublicFooter, PublicTitle, PublicNavbar } from "../components/pagestyle";
import React, { useState, useEffect } from 'react';
import {
    Routes,
    Route,
    useLocation,
    useParams,
    useNavigate
} from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Container from "@mui/material/Container";
import Slider from '@mui/material/Slider'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox';

import { Tooltip } from 'react-tooltip';

import { FaGear, FaTrash } from "react-icons/fa6";
import Modal from 'react-modal'


function NotificationsRow(props) {
    const [showRow, setShowRow] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [sentimentNotificationsChecked, setSetimentNotificationsChecked] = useState(props.sentimentNotificationsChecked);
    const [eiNotificationsChecked, setEiNotificationsChecked] = useState(props.rowData.ei_ev == "Yes");
    const [ciNotificationsChecked, setCiNotificationsChecked] = useState(props.rowData.ci_ev == "Yes");
    const [sentimentRange, setSentimentRange] = useState(sentimentNotificationsChecked ? [props.rowData.l, props.rowData.h] : [-1, 1]);
    const [EIOption, setEIOption] = useState(eiNotificationsChecked ? props.rowData.ei_o : "N/A");
    const [EIConfidence, setEIConfidence] = useState(eiNotificationsChecked ? props.rowData.ei_conf : "N/A");
    const [CIOption, setCIOption] = useState(ciNotificationsChecked ? props.rowData.ci_o : "N/A");
    const [CIConfidence, setCIConfidence] = useState(ciNotificationsChecked ? props.rowData.ci_conf : "N/A");

    const ticker = props.rowData.tk;

    function clickEditNotifications () {
        setShowModal(true);
    }

    function clickDeleteNotification () {
        fetch(`/api/account/notifications?ticker=${encodeURIComponent(ticker)}`, {
            method: "DELETE"
        })
        setShowRow(false);

    }

    function handleNotifications(event) {
        event.preventDefault();
        const sentimentRangeRequest = sentimentNotificationsChecked ? sentimentRange : ["N/A", "N/A"];
        const EIOptionRequest = eiNotificationsChecked ? (EIOption === "N/A" ? "buy" : EIOption) : "";
        const EIConfidenceRequest = eiNotificationsChecked ? EIConfidence : "";
        const CIOptionRequest = ciNotificationsChecked ? (CIOption === "N/A" ? "buy" : CIOption) : "";
        const CIConfidenceRequest = ciNotificationsChecked ? CIConfidence : "";
        fetch(`/api/account/notifications?ticker=${encodeURIComponent(ticker)}&sentiment_low=${sentimentRangeRequest[0]}&sentiment_high=${sentimentRangeRequest[1]}&ei_option=${EIOptionRequest}&ei_min_confidence=${EIConfidenceRequest}&ci_option=${CIOptionRequest}&ci_min_confidence=${CIConfidenceRequest}`, {
            method: "POST"
        })
        setShowModal(false);
    }

    function closeModal() {
        setShowModal(false);
    }

    const handleSentimentRangeChange = (event, newSentimentRange) => {
        setSentimentRange(newSentimentRange);
    };

    const customModalStyles = {
        content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-40%, -40%)',
        display: 'block'
        },
    };
    if (showRow) {
    return (
        <TableRow
        key={ticker}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
        <TableCell component="th" scope="row">
            <FaGear data-tooltip-id="notifications-tooltip" data-tooltip-content={`Set up Notifications for ${ticker}`} onClick={() => clickEditNotifications()}></FaGear>
            <FaTrash data-tooltip-id="notifications-tooltip" data-tooltip-content={`Delete Notifications for ${ticker}`} onClick={() => clickDeleteNotification()}></FaTrash>
        <Modal
            isOpen={showModal}
            onRequestClose={closeModal}
            style={customModalStyles}
            contentLabel="Example Modal"
            fade={false}
        >
            <h2>Edit notifications for {ticker}</h2>
            <form onSubmit={handleNotifications}>
                            <div className="sentiment-range">
                                <Box sx={{width : 600}}>
                                    <Checkbox 
                                        checked={sentimentNotificationsChecked}
                                        onChange={(event) => {
                                            setSetimentNotificationsChecked(event.target.checked);
                                        }} />
                                        Add email subscription for sentiment range {sentimentRange[0]} to {sentimentRange[1]}:
                                        <Box sx={{width : 480, px : 8}}>
                                            <Slider
                                                getAriaLabel={() => 'Sentiment range'}
                                                value={sentimentRange}
                                                onChange={handleSentimentRangeChange}
                                                getAriaValueText={(value) => `${value}`}
                                                min={-1}
                                                max={1}
                                                step={.01}
                                            />
                                        </Box>
                                </Box>
                            </div>
                            <div>
                                <label>
                                    <Checkbox 
                                        checked={ciNotificationsChecked}
                                        onChange={(event) => {
                                            setCiNotificationsChecked(event.target.checked);
                                        }} />
                                        Edit CI direction notifications:
                                    <select onInput={(event) => setCIOption(event.target.value)}>
                                        <option value="buy">buy</option>
                                        <option value="hold">hold</option>
                                        <option value="sell">sell</option>
                                    </select> with at least confidence <input type="text" value={CIConfidence} onChange={(event) => setCIConfidence(event.target.value)} style={{width : 30}}/>%
                                </label>
                            </div>
                            <div>
                                <label>
                                    <Checkbox 
                                        checked={eiNotificationsChecked}
                                        onChange={(event) => {
                                            setEiNotificationsChecked(event.target.checked);
                                        }} />
                                        Edit EI direction notifications:
                                    <select onInput={(event) => setEIOption(event.target.value)}>
                                        <option value="buy">buy</option>
                                        <option value="hold">hold</option>
                                        <option value="sell">sell</option>
                                    </select> with at least confidence <input type="text" value={EIConfidence} onChange={(event) => setEIConfidence(event.target.value)} style={{width : 30}}/>%
                                </label>
                            </div>
                        </form>
            <div style={{display : "flex"}}>
                <button onClick={closeModal}>close</button>
                <button onClick={handleNotifications} style={{marginLeft : "auto"}}>submit</button>
            </div>
        </Modal>
        </TableCell>
        <TableCell>{ticker}</TableCell>
        <TableCell align="right">{sentimentRange[0]}</TableCell>
        <TableCell align="right">{sentimentRange[1]}</TableCell>
        <TableCell align="right">{CIOption}</TableCell>
        <TableCell align="right">{CIConfidence}</TableCell>
        <TableCell align="right">{EIOption}</TableCell>
        <TableCell align="right">{EIConfidence}</TableCell>
        </TableRow>
    )
    } else {return (<></>)}
}

function NotificationsTable(props) {

    const [notifications, setNotifications] = useState([]);

    function clickDelete() {
        setNotifications([]);
        deleteNotifications();
    }

    const deleteNotifications = () => {
        fetch('api/account/notifications/deleteall', {
            method : "DELETE"
        })
            .then((response) => {
                    if (!response.ok)
                        alert('Deletion failed.');
            });
    }

    function createRow(ticker, sentimentValid, low, high, eiDirValid, eiOption, eiConfidence, ciDirValid, ciOption, ciConfidence) {
        return {tk : ticker, sv : sentimentValid, l : low, h : high, ei_ev : eiDirValid, ei_o : eiOption, ei_conf : eiConfidence, ci_ev : ciDirValid, ci_o : ciOption, ci_conf : ciConfidence}
    }
    
    const rows = 
        notifications.map((notif) => {
            let senti_state = notif.sentiment_low === -1 && notif.sentiment_high === -1;
            let ei_opt = notif.ei_option === "";
            let ci_opt = notif.ci_option  === "";
            return createRow(notif.ticker, 
                (senti_state ? "No" : "Yes"), 
                (senti_state ? "N/A" : notif.sentiment_low), 
                (senti_state ? "N/A" : notif.sentiment_high),
                (ei_opt ? "No" : "Yes"), 
                (ei_opt ? "No" : notif.ei_option), 
                (ei_opt ? "No" : notif.ei_min_confidence),
                (ci_opt ? "No" : "Yes"), 
                (ci_opt ? "No" : notif.ci_option), 
                (ci_opt ? "No" : notif.ci_min_confidence)
                );
        })

    useEffect(() => {
        const fetchData = () => {
            fetch('api/account/notifications', {
                method : "GET"
            })
                .then((response) => response.json())
                .then((data) => setNotifications(data.notifications), []);
        }

        fetchData();
    }, [])

    return (
        <>
            <PublicTitle title={`${props.name}'s Notifications`} />
            <Container style={{minHeight: "60vh"}}>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 800 }} aria-label="table">
                    <TableHead>
                    <TableRow>
                        <TableCell> </TableCell>
                        <TableCell>Ticker</TableCell>
                        <TableCell align="auto">Sentiment Range Low</TableCell>
                        <TableCell align="auto">Sentiment Range High</TableCell>
                        <TableCell align="auto">CI Direction</TableCell>
                        <TableCell align="auto">CI Min. Confidence</TableCell>
                        <TableCell align="auto">EI Direction</TableCell>
                        <TableCell align="auto">EI Min. Confidence</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => <NotificationsRow rowData={row} sentimentNotificationsChecked={row.sv === "Yes"} eiNotificationsChecked={row.ei_ev === "Yes"} ciNotificationsChecked={row.ci_ev === "Yes"}/>)}
                    </TableBody>
                </Table>
                </TableContainer>
            {!notifications.length ? 
            <>
                <h3>You have no notifications yet.</h3>
                <p>Use the action button on the left column of TradingPulse to set Notifications.</p>
            </> :
            <button onClick={clickDelete}>Delete All Notifications</button>
            }
            </Container>
            <Tooltip id="notifications-tooltip" multiline={true} data-html={true} style={{ zIndex: "105", backgroundColor: "white", color: "black", boxShadow: "0 0 5px #D9D9D9" }} />

        </>
    )
}

export default function Notifications() {

    let location = useLocation();

    const navigate = useNavigate();

    window.document.title = "BAM.money | Notifications";

    const [username, setUsername] = useState("");

    const checkIfLoggedIn = () => {
        if (("Yes" == localStorage.getItem("user-is-logged-in-bam-money")) 
            && (Date.now() - (localStorage.getItem("user-time-log-in-bam-money") ? localStorage.getItem("user-time-log-in-bam-money") : 0) < 120000)) {
            setUsername(localStorage.getItem("username-bam-money"))
            return;
        }
        fetch('/api/account', {})
            .then((response) => response.json())
            .then((json) => {
                if (json.response === "registered") setUsername(json.name);
                else navigate("/api/login");
            })
            .catch(() => navigate("/api/login"));
    }

    useEffect(() => {
        checkIfLoggedIn();
      }, []);

    return (
        <>
            <PublicNavbar />
            <NotificationsTable name = {username}/>
            <PublicFooter />
        </>
    )
}