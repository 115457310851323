
import React, { useRef, useState, useEffect, useLayoutEffect } from "react";
import useResizeObserver from '@react-hook/resize-observer';
import '../css/d3.css'
import {
    select,
    line,
    curveCardinal,
    scaleLinear,
    axisBottom,
    axisLeft,
    svg,
    scaleTime,
    scaleSequential,
    extent,
    timeMonth,
    timeDay,
    min,
    max,
    symbol,
    symbolCircle,
    symbolsStroke
} from "d3";
import { Tooltip } from 'react-tooltip';




function getFormattedDate(date) {
    return `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()}`;
}

function createTooltip(d, hp) {
    try {
        const hpp = (hp < 30 ? hp : (hp-29)) + 1
        const hp_string = hp < 30 ? 'days' : 'months'
        const num = d < 0.5 ? -(1-d) : d
        return `${hpp} ${hp_string}: ${Math.min(Math.max(num.toFixed(2), -0.99), 0.99)}`
    }
    catch {
        return `Failed to load information.`
    }
}

const useSize = (target) => {
    const [size, setSize] = useState()
    useLayoutEffect(() => {
        setSize(target.current.getBoundingClientRect())
    }, [target])
    useResizeObserver(target, (entry) => setSize(entry.contentRect))
    return size
}

//chart component
export default function D3SignalHeatMap({
    data, recs
}) {
    //refs
    const svgRef = useRef();
    const boundingRef = useRef();
    const size = useSize(boundingRef);


    //draws chart
    useEffect(() => {
        const svg = select(svgRef.current);
        svg.selectAll("*").remove();
        if (!size) return
        const width = size.width;
        const height = size.height;


        const colorScale = scaleLinear()
            .domain([0, 0.5, 1])  // Input range: from -1 to 1
            .range(["rgb(255, 82, 82)", 'rgb(243, 243, 243)', "rgb(144, 190, 109)"]);        //axes
        const rectWidth = width / data.length;
        
        // Create rectangles for the heatmap
        svg.selectAll("rect")
            .data(data.map((item) => {
                var val = 0.5
                if (item > 0) val = item
                else val = item + 1.0
                return val
            }))
            .enter()
            .append("rect")
            .attr("x", (d, i, r) => i * rectWidth)
            .attr("y", 0)
            .attr("width", rectWidth*0.99)
            .attr("height", height)
            .attr("data-tooltip-id", "d3-pnl-tooltip")
            .attr("data-tooltip-html", (d, i, r) => createTooltip(d, i))
            .attr("fill", d => colorScale(d))
            .attr("stroke", (d,i,r) => (recs.includes(i) ? "black" : "none"))      // Sets the border color to black
            .attr("stroke-width", 1);     // Sets the border thickness to 1px;
    }, [data, size, recs]);

    var boundingStyle = { height: "100%", width: "100%" }

    return (
        <div ref={boundingRef} style={boundingStyle}>
            <div>
                <svg ref={svgRef} style={{ height: "100%", width: "100%" }}>
                </svg>
            </div>
            <Tooltip id="d3-pnl-tooltip" multiline={true} data-html={true} style={{ zIndex: "105", backgroundColor: "white", color: "black", boxShadow: "0 0 5px #D9D9D9" }} />
        </div>
    );
};
