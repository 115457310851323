import './css/App.css';
import Home from './pages/home.js';
import Terms from './pages/terms.js';
import { TradersLanding, NewLanding, AnalystLanding } from './pages/demo-landing.js'
import ConfidentialityAndPrivacy from './pages/confidentiality-and-privacy.js';
import Company from './pages/company.js';
import FAQ from './pages/faq.js';
import Product from './pages/product.js';
import ForBanks from './pages/forbanks.js';
import ForAMs from './pages/forassetmanagers.js';
import Profile from './pages/profile.js';
import Marketpulse from './pages/marketpulse.js';
import Analyst from './pages/analyst.js';
import Notifications from './pages/notifications.js';
import Register from './pages/register.js';
import Subscription from './pages/subscription.js';
import AllPDF from './pages/allpdf.js';
import CustomData from './pages/custom-data.js';
import { AliceChat } from './pages/alice.js';
import { ChartGen } from './internal/chartgen.js'
import { ArticlesMain } from './pages/articles.js';
import { HowItWorks } from './pages/howItWorks.js';

import { Routes, Route, useParams } from 'react-router-dom';

import Contact from './pages/contact';
import Unsubscribe from './pages/unsubscribe.js';
import AutoTrade from './pages/autotrade.js';

function App() {
  const url = window.location.href;
  const urlObj = new URL(url);
  const params = new URLSearchParams(urlObj.search);
  if (params.has('source_email')) {
    const sourceEmail = params.get('source_email');
    fetch(`/api/public/public-campaign-log?source_email=${sourceEmail}`)
  }

  return (
    <div className='app'>
      <Routes>
        <Route path='/terms' element={<Terms />} />
        <Route
          path='/confidentiality-and-privacy'
          element={<ConfidentialityAndPrivacy />}
        />
        <Route path='/tradingpulse/*' element={<Marketpulse />} />
        <Route path='/analysis/*' element={<ArticlesMain />} />
        <Route path='/analyst/:name' element={<Analyst />} />
        <Route path='/custom-data' element={<CustomData />} />
        <Route path='/for-traders' element={<TradersLanding />} />
        <Route path='/for-analysts' element={<AnalystLanding />} />
        <Route path='/landing' element={<NewLanding />} />
        <Route path='/notifications' element={<Notifications />} />
        <Route path='/company' element={<Company />} />
        <Route path='/resources/*' element={<Product />} />
        <Route path='/faq' element={<FAQ />} />
        <Route path='/for-banks' element={<ForBanks />} />
        <Route path='/for-asset-managers' element={<ForAMs />} />
        <Route path='/how-it-works' element={<HowItWorks />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/profile' element={<Profile />} />
        <Route path='/register' element={<Register />} />
        <Route path='/subscription' element={<Subscription />} />
        <Route path='/all-pdf' element={<AllPDF />} />
        <Route path='/auto-trade' element={<AutoTrade />} />
        <Route path='/unsubscribe' element={<Unsubscribe />} />
        <Route path='/chart-gen' element={<ChartGen />} />
        <Route path='*' element={<Home />} />
      </Routes>
    </div>
  );
}

export default App;
