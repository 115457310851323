import React, { useState, useEffect } from 'react'
import { PublicFooter, PublicTitle, PublicNavbar } from "../components/pagestyle";
import { FaAngleLeft } from "react-icons/fa6";
import { Tooltip } from 'react-tooltip';
import {Button} from '@mui/material';


export function HowItWorks() {
    window.document.title = "BAM.money | Alpha-enhancing Use Cases";

    const [pageState, setPageState] = useState("main");

    return (
        <>
            <PublicNavbar />
            {
                pageState == "main" ?
                    <>
                        <h1>Alpha-Enhancing Use Cases:</h1>
                        <h2 style={{ color: "#1976d2" }}>Start with 3 Core Ones to Choose From</h2>
                        <div style={{ display: "flex", margin: "auto" }}>
                            <div onClick={() => setPageState("A")} style={{ width: "calc(29% - 10px)", backgroundColor: "#1976d2", margin: "10px", borderRadius: "30px", color: "white", padding: "2%" }}>
                                <img alt="" src="images/how-it-works-1.png" style={{ width: "40%", margin: "10%" }} />
                                <h1>Focus on Pressing Matters</h1>
                                <p>Data insights help you target corporations primed for price action</p>
                            </div>
                            <div onClick={() => setPageState("B")} style={{ width: "calc(29% - 10px)", backgroundColor: "#1976d2", margin: "10px", borderRadius: "30px", color: "white", padding: "2%"  }}>
                                <img alt="" src="images/how-it-works-2.png" style={{ width: "40%", margin: "10%" }} />
                                <h1>Find Relative Value</h1>
                                <p>Companies ranked by future performance, risk scenarios, and strategies</p>
                            </div>
                            <div onClick={() => setPageState("C")} style={{ width: "calc(29% - 10px)", backgroundColor: "#1976d2", margin: "10px", borderRadius: "30px", color: "white", padding: "2%"  }}>
                                <img alt="" src="images/how-it-works-3.png" style={{ width: "40%", margin: "10%" }} />
                                <h1>Find Hedges, Optimal Baskets</h1>
                                <p>Compare ETFs and portfolios or new reccomendations. Discover optimal hedges</p>
                            </div>
                        </div>
                    </>
                    :
                    <div style={{ position: "relative", width: "100%" }}>
                        <div onClick={() => setPageState("main")} style={{ position: "absolute", left: "5%", top: "0", width: "80px" }}>
                            <FaAngleLeft style={{ scale: "2" }} />
                        </div>
                        {
                            pageState == "A" &&
                            <>
                                <h2>Focus on Pressing Matters</h2>
                                <div style={{ display: "flex", margin: "auto" }}>
                                    <div style={{ width: "calc(33% - 10px)", margin: "10px", borderRadius: "30px", border: "2px solid #1976d2" }}>
                                        <h1 style={{ fontSize: "100px", margin: "0" }}>1</h1>
                                        <h1 style={{ margin: "0" }}>View Tailored Signals + Metrics</h1>
                                        <p>Key metrics needed to quickly make or support decisions. AI driven signal based on your customized needs and views.</p>
                                    </div>
                                    <div style={{ width: "calc(33% - 10px)", margin: "10px", borderRadius: "30px", border: "2px solid #1976d2" }}>
                                        <h1 style={{ fontSize: "100px", margin: "0" }}>2</h1>
                                        <h1 style={{ margin: "0" }}>Compare with Current Professional Analysts</h1>
                                        <p>Agree or disagree with the signal, and see how your opinion compares with top analysts (over 3k of them).</p>
                                    </div>
                                    <div style={{ width: "calc(33% - 10px)", margin: "10px", borderRadius: "30px", border: "2px solid #1976d2" }}>
                                        <h1 style={{ fontSize: "100px", margin: "0" }}>3</h1>
                                        <h1 style={{ margin: "0" }}>View Comprehensive Analysis</h1>
                                        <p>View detailed analyses. Which strategies are better recommended? What history has to say about this signal?</p>
                                    </div>
                                </div>
                            </>
                        }
                        {
                            pageState == "B" &&
                            <>
                                <h2>Find Relative Value</h2>
                                <div style={{ display: "flex", margin: "auto" }}>
                                    <div style={{ width: "calc(33% - 10px)", margin: "10px", borderRadius: "30px", border: "2px solid #1976d2" }}>
                                        <h1 style={{ fontSize: "100px", margin: "0" }}>1</h1>
                                        <h1 style={{ margin: "0" }}>Filter by Corporate Names</h1>
                                        <p>Find the corporates with the strongest signals. Filter by names, indices, sectors, etc</p>
                                    </div>
                                    <div style={{ width: "calc(33% - 10px)", margin: "10px", borderRadius: "30px", border: "2px solid #1976d2" }}>
                                        <h1 style={{ fontSize: "100px", margin: "0" }}>2</h1>
                                        <h1 style={{ margin: "0" }}>Use Relative Value Column</h1>
                                        <p>The column gives you the equity or credit with the most relevant  names based on current dynamics</p>
                                    </div>
                                    <div style={{ width: "calc(33% - 10px)", margin: "10px", borderRadius: "30px", border: "2px solid #1976d2" }}>
                                        <h1 style={{ fontSize: "100px", margin: "0" }}>3</h1>
                                        <h1 style={{ margin: "0" }}>Explore in Depth Metrics</h1>
                                        <p>You can further view clustered names by clicking on the Relative Value field and seeing company lists machine ranked</p>
                                    </div>
                                </div>
                            </>
                        }
                        {
                            pageState == "C" &&
                            <>
                                <h2>Find Hedges and Optimal Baskets</h2>
                                <div style={{ display: "flex", margin: "auto" }}>
                                    <div style={{ width: "calc(33% - 10px)", margin: "10px", borderRadius: "30px", border: "2px solid #1976d2" }}>
                                        <h1 style={{ fontSize: "100px", margin: "0" }}>1</h1>
                                        <h1 style={{ margin: "0" }}>Filter by Corporate Names</h1>
                                        <p>Find the corporates with the strongest signals. Filter by names, indices, sectors, etc</p>
                                    </div>
                                    <div style={{ width: "calc(33% - 10px)", margin: "10px", borderRadius: "30px", border: "2px solid #1976d2" }}>
                                        <h1 style={{ fontSize: "100px", margin: "0" }}>2</h1>
                                        <h1 style={{ margin: "0" }}>Check for Similar Companies + Hedges</h1>
                                        <p>See optimal and natural hedges. Check those companies to deepen your research</p>
                                    </div>
                                    <div style={{ width: "calc(33% - 10px)", margin: "10px", borderRadius: "30px", border: "2px solid #1976d2" }}>
                                        <h1 style={{ fontSize: "100px", margin: "0" }}>3</h1>
                                        <h1 style={{ margin: "0" }}>Make Informed Investment Decisions</h1>
                                        <p>Use our information to make informed decisions about your trades, giving you the quantitative analysis you need at a moments notice</p>
                                    </div>
                                </div>
                            </>
                        }
                        {
                            pageState == "tradingpulse" &&
                            <>
                                <h2>Hover to See How Tradingpulse Works</h2>
                                <div style={{position: "relative", width: "100%"}}>
                                    <img alt="" src="images/tradingpulse-example.png" width="100%" className="truecolor"/>
                                    <div 
                                        style={{top: "7%", left: "1%", height: "6%", width: "53%", position: "absolute", border: "1px solid #1976d2", backgroundColor: "rgba(25, 118, 210, .2)", borderRadius: "10px"}}
                                        data-tooltip-id="how-it-works-tooltip"
                                        data-tooltip-html="<p style='max-width: 40vw'>Search by stock ticker, CUSIP, or debtor name, and filter based on index membership or sector</p>"
                                    />
                                    <div 
                                        style={{top: "17%", left: "0%", height: "9%", width: "4.3%", position: "absolute", border: "1px solid #1976d2", backgroundColor: "rgba(25, 118, 210, .2)", borderRadius: "10px"}}
                                        data-tooltip-id="how-it-works-tooltip"
                                        data-tooltip-html="<p style='max-width: 40vw'>Select your favorites by clicking the star under Actions and toggle here to view</p>"
                                    />
                                    <div 
                                        style={{top: "17%", left: "4.7%", height: "9%", width: "3%", position: "absolute", border: "1px solid #1976d2", backgroundColor: "rgba(25, 118, 210, .2)", borderRadius: "10px"}}
                                        data-tooltip-id="how-it-works-tooltip"
                                        data-tooltip-html="<p style='max-width: 40vw'>ETF predictions are average weighted by confidences and constituent company participation in the ETF.</p>"
                                    />
                                    <div 
                                        style={{top: "15%", left: "14%", height: "13%", width: "49%", position: "absolute", border: "1px solid #1976d2", backgroundColor: "rgba(25, 118, 210, .2)", borderRadius: "10px"}}
                                        data-tooltip-id="how-it-works-tooltip"
                                        data-tooltip-html="<p style='max-width: 40vw'><strong>Displays the overall PnL of all companies filtered</strong> based off of the specific search result. This is calculated with an even weight on each company, no matter its market size.</p>"
                                    />
                                    <div 
                                        style={{top: "13%", left: "68%", height: "17%", width: "9%", position: "absolute", border: "1px solid #1976d2", backgroundColor: "rgba(25, 118, 210, .2)", borderRadius: "10px"}}
                                        data-tooltip-id="how-it-works-tooltip"
                                        data-tooltip-html="<p style='max-width: 40vw'><strong>You can choose a market regime - </strong>your index prediction-to get signals tailored to your macro strategy</p>"
                                    />
                                    <div 
                                        style={{top: "14%", left: "79.6%", height: "15.5%", width: "19.5%", position: "absolute", border: "1px solid #1976d2", backgroundColor: "rgba(25, 118, 210, .2)", borderRadius: "10px"}}
                                        data-tooltip-id="how-it-works-tooltip"
                                        data-tooltip-html="<p style='max-width: 40vw'><strong>Displays the best forward looking trades</strong> based on last day's price movements and our signals </p>"
                                    />
                                    <div 
                                        style={{top: "32%", left: ".5%", height: "9%", width: "13%", position: "absolute", border: "1px solid #1976d2", backgroundColor: "rgba(25, 118, 210, .2)", borderRadius: "10px"}}
                                        data-tooltip-id="how-it-works-tooltip"
                                        data-tooltip-html="<p style='max-width: 40vw'>Company Name and Stock Ticker.</p>"
                                    />
                                    <div 
                                        style={{top: "42%", left: ".5%", height: "7%", width: "13%", position: "absolute", border: "1px solid #1976d2", backgroundColor: "rgba(25, 118, 210, .2)", borderRadius: "10px"}}
                                        data-tooltip-id="how-it-works-tooltip"
                                        data-tooltip-html="<p style='max-width: 40vw'>Click here to get an in depth look at this company (heat maps, etc)</p>"
                                    />
                                    <div 
                                        style={{top: "32%", left: "14.5%", height: "9%", width: "17%", position: "absolute", border: "1px solid #1976d2", backgroundColor: "rgba(25, 118, 210, .2)", borderRadius: "10px"}}
                                        data-tooltip-id="how-it-works-tooltip"
                                        data-tooltip-html="<p style='max-width: 40vw'><strong>Select your anticipated hold period-</strong> to see the trading indicator (Equity/EI or Credit/CI). <br />The chart shows the company's performance relative to it's relevant index over a that hold period</p>"
                                    />
                                    <div 
                                        style={{top: "42%", left: "26%", height: "7%", width: "2.5%", position: "absolute", border: "1px solid #1976d2", backgroundColor: "rgba(25, 118, 210, .2)", borderRadius: "10px"}}
                                        data-tooltip-id="how-it-works-tooltip"
                                        data-tooltip-html="<p style='max-width: 40vw'>Click the light bulb.<br />Be surprised! This displays which analysts agree with you or BAM</p>"
                                    />
                                    <div 
                                        style={{top: "32%", left: "33%", height: "9%", width: "22%", position: "absolute", border: "1px solid #1976d2", backgroundColor: "rgba(25, 118, 210, .2)", borderRadius: "10px"}}
                                        data-tooltip-id="how-it-works-tooltip"
                                        data-tooltip-html="<p style='max-width: 40vw'>Select a historical time horizon to see from the drop down choices:<ul><li><strong>Historical Active PnL-</strong> Profit & Loss resulting from trades executed based on signals</li><li><strong>Insider & Sentiment-</strong> Sentiment Scores on relevant Digital Media </li><li><strong>News Flow-</strong> Volume of news relative to itself over 30 days</li></ul></p>"
                                    />
                                    <div 
                                        style={{top: "42%", left: "33%", height: "7%", width: "22%", position: "absolute", border: "1px solid #1976d2", backgroundColor: "rgba(25, 118, 210, .2)", borderRadius: "10px"}}
                                        data-tooltip-id="how-it-works-tooltip"
                                        data-tooltip-html="<p style='max-width: 40vw'>View of whatever chart you select over the given time horizon. </p>"
                                    />
                                    <div 
                                        style={{top: "32%", left: "56%", height: "9%", width: "15.5%", position: "absolute", border: "1px solid #1976d2", backgroundColor: "rgba(25, 118, 210, .2)", borderRadius: "10px"}}
                                        data-tooltip-id="how-it-works-tooltip"
                                        data-tooltip-html="<p style='max-width: 40vw'>Select from the drop down choices: <ul><li><strong>Risk Metrics-</strong> Value at risk, Expected Shortfall, Peek-to-Trough</li><li><strong>Sharpe Ratio-</strong> Passive and Active</li> </ul></p>"
                                    />
                                    <div 
                                        style={{top: "42%", left: "56%", height: "7%", width: "15.5%", position: "absolute", border: "1px solid #1976d2", backgroundColor: "rgba(25, 118, 210, .2)", borderRadius: "10px"}}
                                        data-tooltip-id="how-it-works-tooltip"
                                        data-tooltip-html="<p style='max-width: 40vw'>View the metrics selected in the above column</p>"
                                    />
                                    <div 
                                        style={{top: "32%", left: "73%", height: "9%", width: "15.5%", position: "absolute", border: "1px solid #1976d2", backgroundColor: "rgba(25, 118, 210, .2)", borderRadius: "10px"}}
                                        data-tooltip-id="how-it-works-tooltip"
                                        data-tooltip-html="<p style='max-width: 40vw'>Select from the drop down choices:<ul><li><strong>Similar Companies-</strong> companies with similar market or fundamental ratios to the target</li><li><strong>Relative Value-</strong> Based on the most similar companies that presents the highest confidence will move the opposite direction</li></ul></p>"
                                    />
                                    <div 
                                        style={{top: "42%", left: "73%", height: "7%", width: "15.5%", position: "absolute", border: "1px solid #1976d2", backgroundColor: "rgba(25, 118, 210, .2)", borderRadius: "10px"}}
                                        data-tooltip-id="how-it-works-tooltip"
                                        data-tooltip-html="<p style='max-width: 40vw'>View Similar Companies or Relative Value shown here </p>"
                                    />
                                    <div 
                                        style={{top: "32%", left: "89.5%", height: "9%", width: "10%", position: "absolute", border: "1px solid #1976d2", backgroundColor: "rgba(25, 118, 210, .2)", borderRadius: "10px"}}
                                        data-tooltip-id="how-it-works-tooltip"
                                        data-tooltip-html="<p style='max-width: 40vw'>Select favorite companies, create email notifications, download rich and customizable reports or historical data</p>"
                                    />
                                    <div 
                                        style={{top: "42%", left: "89.5%", height: "7%", width: "10%", position: "absolute", border: "1px solid #1976d2", backgroundColor: "rgba(25, 118, 210, .2)", borderRadius: "10px"}}
                                        data-tooltip-id="how-it-works-tooltip"
                                        data-tooltip-html="<p style='max-width: 40vw'>Icons to favorite (star), create email alerts (bell), reports (book) download data.</p>"
                                    />
                                </div>
                                <Tooltip id="how-it-works-tooltip" multiline={true} data-html={true} style={{ zIndex: "105", backgroundColor: "#1976d2", color: "var(--light-font)", boxShadow: "0 0 5px #D9D9D9" }} />
                            </>
                        }
                    </div>
            }
            <br />
            {
                pageState != "tradingpulse" &&
                <Button variant="contained" color="primary" onClick={() => setPageState("tradingpulse")}>
                    See TradingPulse
                    </Button>
            }
            <div style={{height: "20vh"}}></div>

            <PublicFooter />
        </>
    );
}